import { ExtendedCustomModule, PlacesModule, initPlacesModule } from '@caresend/ui-components';

export const placesModuleExtension = {};

export const placesModule: ExtendedCustomModule<
PlacesModule,
    typeof placesModuleExtension
> = initPlacesModule(placesModuleExtension);

export type ExtendedPlacesModule = typeof placesModule;

export type ExtendedPlacesMutations = ExtendedPlacesModule['mutations'];
export type ExtendedPlacesActions = ExtendedPlacesModule['actions'];
export type ExtendedPlacesGetters = ExtendedPlacesModule['getters'];
