
import { DenormalizedDraftWaypoint, DraftWaypointStatus } from '@caresend/types';
import { EmptyOrdersList, Loading, OrderWaypointCard, useDraftWaypointStore } from '@caresend/ui-components';
import { computed, defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'VisitsList',
  components: {
    EmptyOrdersList,
    Loading,
    OrderWaypointCard,
  },
  setup() {
    const draftWaypointStore = useDraftWaypointStore();

    const denormalizedDraftWaypoints = computed<DenormalizedDraftWaypoint[]>(() =>
      draftWaypointStore.getters.getDraftWaypoints(),
    );

    const sortedVisits = computed<DenormalizedDraftWaypoint[]>(() =>
      denormalizedDraftWaypoints.value.slice().sort((a, b) => {
        if (a.status === DraftWaypointStatus.PENDING
          && b.status !== DraftWaypointStatus.PENDING) return -1;
        if (a.status !== DraftWaypointStatus.PENDING
          && b.status === DraftWaypointStatus.PENDING) return 1;

        const aDate = a.date?.timestamp ?? a.date?.timestamp ?? 0;
        const bDate = b.date?.timestamp ?? b.date?.timestamp ?? 0;

        return new Date(aDate).getTime() - new Date(bDate).getTime();
      }),
    );

    onMounted(async () => {
      await draftWaypointStore.fetchDenormalizedDraftWaypoints();
    });

    const isLoading = computed<boolean>(() => draftWaypointStore.isLoading);

    const visitsListIsEmpty = computed<boolean>(() =>
      !isLoading.value && !!sortedVisits.value && !sortedVisits.value.length,
    );

    return {
      isLoading,
      sortedVisits,
      visitsListIsEmpty,
    };
  },
});
