import {
  Booking,
  ByID,
  FriendlyStatus,
  Procedure,
  WithFriendlyStatus,
} from '@caresend/types';
import { AnyGetters } from '@caresend/ui-components';
import update from 'immutability-helper';

import type { RootState } from '@/store/model';
import { BookingsState } from '@/store/modules/bookings/model';

type S = BookingsState;

const bookingsState: S = {
  bookings: {},
};

export const bookingsMutations = {
  'bookings/RESET_BOOKINGS': (state: S) => {
    state.bookings = {};
  },

  'bookings/SET_BOOKING': (state: S, booking: Booking) => {
    const bookingID = booking.id;

    state.bookings = update(state.bookings ?? {}, {
      [bookingID]: { $set: booking },
    });
  },

  'bookings/SET_BOOKINGS': (state: S, bookings: ByID<Booking>) => {
    state.bookings = {
      ...state.bookings,
      ...bookings,
    };
  },

  'bookings/UNSET_BOOKING': (state: S, bookingID: string) => {
    if (state.bookings) {
      state.bookings = update(state.bookings, {
        $unset: [bookingID],
      });
    }
  },
};

export const bookingsGetters = {
  'bookings/getFirstProcedure': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (bookingID: string): WithFriendlyStatus<Procedure> | null => {
    const booking = state.bookings[bookingID];
    const firstProcedureID = Object.keys(booking?.procedures ?? {})[0];
    if (!firstProcedureID) return null;
    return rootState.procedures.procedures[firstProcedureID] ?? null;
  },

  'bookings/getFriendlyStatus': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
  ) => (bookingID: string): FriendlyStatus | null => {
    const firstProcedure = bookingsGetters[
      'bookings/getFirstProcedure'
    ](state, getters, rootState)(bookingID);
    return firstProcedure?.friendlyStatus ?? null;
  },
};

export type BookingsGetters = typeof bookingsGetters;

export const bookingsModule = {
  state: bookingsState,
  mutations: bookingsMutations,
  actions: {},
  getters: bookingsGetters,
};

export type BookingsModule = typeof bookingsModule;
