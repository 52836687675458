import { ExtendedCustomModule, OrdersModule, initOrdersModule } from '@caresend/ui-components';

import type { RootState } from '@/store/model';
import { ExtendedOfficesActions } from '@/store/modules/offices';
import { ExtendedUsersActions, ExtendedUsersMutations } from '@/store/modules/users';
import { ExtendedVariablesGetters } from '@/store/modules/variables';

const ordersModuleExtension = {
};

export const ordersModule: ExtendedCustomModule<
OrdersModule<
RootState,
ExtendedUsersMutations,
ExtendedOfficesActions & ExtendedUsersActions,
ExtendedVariablesGetters
>,
  typeof ordersModuleExtension
> = initOrdersModule(ordersModuleExtension);

export type ExtendedOrdersModule = typeof ordersModule;

export type ExtendedOrdersMutations = ExtendedOrdersModule['mutations'];
export type ExtendedOrdersActions = ExtendedOrdersModule['actions'];
export type ExtendedOrdersGetters = ExtendedOrdersModule['getters'];
