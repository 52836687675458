import { BaseRouteMeta, DevRouteName, SchedulingFlowRouteName } from '@caresend/ui-components';
import { Location, Route } from 'vue-router';

export interface CustomRouteMeta extends BaseRouteMeta {
}

/**
 * Booking routes should remain disabled via `patientInitiatedBooking` feature
 * flag until refactored to work with latest data structures.
 */
export enum PatientRouteName {
  FINISH_SIGN_UP = 'FinishSignUp',
  FORGOT_PASSWORD = 'ForgotPassword',
  HOME = 'Home',
  LOGIN = 'Login',
  PAGE_NOT_FOUND = 'PageNotFound',
  RESET_PASSWORD = 'ResetPassword',
  VERIFY_EMAIL = 'VerifyEmail',
  USER_SETTINGS = 'UserSettings',
}

export type RouteName = PatientRouteName
| DevRouteName
| SchedulingFlowRouteName;

export const routeNames = {
  ...PatientRouteName,
  ...DevRouteName,
  ...SchedulingFlowRouteName,
};

export type LocationData = Omit<Location, 'name' | 'path'>;

/**
 * Returns a single route meta object composed by combining all of the meta
 * objects on each matching route.
 */
export const getMatchedMeta = (route: Route) =>
  route.matched.reduce<CustomRouteMeta>((previousMeta, matched) => ({
    ...previousMeta,
    ...matched.meta,
  }), {});

export const getPropsFromQueryAndParams = (route: Route) => ({
  ...route.query,
  ...route.params,
});
