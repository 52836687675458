
import { Circles } from '@caresend/ui-components';
import Vue from 'vue';
import Nl2br from 'vue-nl2br';

import PWBIcon from '@/components/layout/pages/PageWithIcon/components/PWBIcon.vue';

Vue.component('Nl2br', Nl2br);

export default Vue.extend({
  name: 'PageWithIcon',
  components: {
    Circles,
    PWBIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: '',
    },
    titleDescription: {
      type: String,
      default: '',
    },
    textDescription: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    svgIcon: {
      type: Boolean,
      default: false,
    },
  },
});
