import {
  AuthStatus,
  DbRef,
  EncryptedUser,
  PhoneNumber,
  Role,
  UserStatus,
} from '@caresend/types';
import {
  dbSet,
  dbUpdate,
  firebaseAuth,
  getStore,
  getValueOnce,
  toastError,
  toastErrorAndReport,
  toastResolve,
  toastSuccess,
} from '@caresend/ui-components';
import { getUserPath } from '@caresend/utils';
import { updateEmail as firebaseUpdateEmail } from 'firebase/auth';

import { patientURL, webProURL } from '@/data/urls';
import {
  addMembersToUserRequest,
  checkEmailAlreadyInUseRequest,
  createUserWithEmailLinkRequest,
  getTokenForIncompleteUserAccessRequest,
  resetPasswordRequest,
  validateTokenRequest,
} from '@/database/firebase/API';
import { signInWithToken } from '@/database/firebase/token';
import { identifyAnonymousUserSegment } from '@/functions/tracking';

export const validateToken = async (token: string) => {
  try {
    const { userID } = await validateTokenRequest({ token });
    return userID;
  } catch (error) {
    throw toastErrorAndReport('Invalid token', error);
  }
};

export const incompleteSignIn = async (
  { bookingID, draftWaypointID, dateOfBirth, proxyAuthID }: {
    bookingID?: string;
    draftWaypointID?: string;
    dateOfBirth: number;
    proxyAuthID?: string;
  }) => {
  const store = getStore();

  try {
    const { token } = await getTokenForIncompleteUserAccessRequest({
      bookingID,
      draftWaypointID,
      proxyAuthID,
      dateOfBirth,
      role: Role.PATIENT,
    });
    await signInWithToken({ token });
  } catch (error) {
    if (store.state.auth.authStatus === AuthStatus.FIREBASE_AUTH) firebaseAuth.signOut();
    toastError(error);
  }
};

export const registerUserWithoutPassword = async (
  user: EncryptedUser,
  members?: EncryptedUser[],
  requestToken?: boolean,
) => {
  const emailLinkURL = user.role === 'patient' ? patientURL : webProURL;
  try {
    const { id, token } = await createUserWithEmailLinkRequest({
      user,
      emailLinkURL,
      members,
      requestToken,
    });

    if (token) await signInWithToken({ token });
    else toastSuccess('Confirmation email sent!');

    return id;
  } catch (error) {
    throw toastError(error);
  }
};

export const addMembersToUser = (userID: string, members: Array<EncryptedUser>) =>
  new Promise<boolean>((resolve, reject) => {
    const emailLinkURL = patientURL;
    addMembersToUserRequest({ userID, emailLinkURL, members })
      .then(() => toastResolve(resolve, 'Member added!', true))
      .catch((error: unknown) => reject(toastError(error)));
  });

export const verifyStatusIncomplete = async (userID: string): Promise<boolean> => {
  const user = (await getValueOnce(`${DbRef.USERS}/${userID}`)) as EncryptedUser;
  const { status } = user;
  if (status === UserStatus.INCOMPLETE) return true;
  throw toastError('Account already registered');
};

// TODO: Consider using `changeUserEmailAddress` implementation from pro,
// and moving to ui-components
export const updateEmail = async (userID: string, email: string) => {
  const { currentUser } = firebaseAuth;
  if (!currentUser) throw Error('Not signed in');
  await firebaseUpdateEmail(currentUser, email);
  const path = getUserPath(userID, 'info/email');
  await dbSet<string>(path, email);
};

export const updatePhone = async (userID: string, phone: PhoneNumber) => {
  const { currentUser } = firebaseAuth;
  if (!currentUser) throw toastError('Not signed in');
  try {
    const path = getUserPath(userID, 'info/phone');
    await dbUpdate<PhoneNumber>(path, phone);
  } catch {
    throw toastError('There was an issue updating your phone number');
  }
};

export const resetPassword = (email: string, redirect?: string) =>
  new Promise<boolean>((resolve, reject) => {
    resetPasswordRequest({ email, emailLinkURL: patientURL, redirect })
      .then(() => {
        toastResolve(
          resolve,
          'We just sent you an email, please check your inbox to reset your password.',
          true,
        );
      })
      .catch((error: unknown) => {
        toastError(error);
        reject();
      });
  });

/** Firebase auth has initialized and the user is not signed in. */
export const handleAuthInitializedWithoutUser = () => identifyAnonymousUserSegment();

export const checkEmailAlreadyInUse = (email: string) =>
  new Promise<{ email: string; isInUse: boolean }>((resolve) => {
    checkEmailAlreadyInUseRequest({ email }).then((result) => {
      const isInUse = result.isInUse as boolean;
      return resolve({ isInUse, email });
    });
  });

export const updateUserStatusOnVerification = async (userID: string): Promise<void> => {
  const user = await getValueOnce<EncryptedUser>(`${DbRef.USERS}/${userID}`);
  if (!user) throw Error('Unable to update user status on verification');
  if (user.role !== Role.PATIENT) return;

  await dbSet<UserStatus>(getUserPath(userID, 'status'), UserStatus.APPROVED);
};
