
import { BasicCard, ButtonComponent, Copy, DatePicker } from '@caresend/ui-components';
import { PropType, computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'IncompleteLogin',
  components: {
    Copy,
    DatePicker,
    ButtonComponent,
    BasicCard,
  },
  props: {
    loading: Boolean,
    userFirstName: {
      type: String,
      required: true,
    },
    schedulingForName: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
  },
  setup() {
    const dateOfBirth = ref<number | undefined>(undefined);

    const dateEntered = computed<boolean>(() => !!dateOfBirth.value);

    return {
      dateEntered,
      dateOfBirth,
    };
  },
});
