import { EncryptedUser, SegmentTrackProperties, TrackingEvent } from '@caresend/types';
import { initSentry, isProd } from '@caresend/ui-components';
import { formatFullName, formatPhoneNumber, isNullish } from '@caresend/utils';
import isEqual from 'lodash.isequal';
import Vue from 'vue';

import { isLocal } from '@/data/urls';
import { SEGMENT_TOKEN, SENTRY_DSN } from '@/database/credentials';
import { formatDateOfBirth } from '@/functions/date';

export const initializeSentry = () => {
  initSentry(isProd, {
    Vue,
    dsn: !isLocal ? SENTRY_DSN : undefined,
    release: `${process.env.VITE_NAME}@${process.env.VITE_VERSION}@${process.env.VITE_CI_COMMIT_SHORT_SHA}`,
  });
};

/**
 * Segment
 */

export const initSegment = () => {
  window.analytics.load(SEGMENT_TOKEN);
};

export const trackPageViewSegment = () => {
  window.analytics.page();
};

export const identifyAnonymousUserSegment = () => {
  window.analytics.identify();
};

export const getSegmentIdentityFromState = (user: EncryptedUser) => {
  const { info, role, status } = user;
  const { email, phone, address, gender, dateOfBirth, languages } = info;
  const name = formatFullName(user);
  return {
    email,
    name,
    role,
    address: address?.formattedAddress,
    phone: formatPhoneNumber(phone),
    gender,
    dateOfBirth: formatDateOfBirth(dateOfBirth),
    languagesName: Object.values(languages ?? {}).map((language) => language.language),
    languagesStatus: Object.values(languages ?? {}).map((language) => language.fluency),
    status,
  };
};

export const identifyAuthenticatedUserSegment = (user: EncryptedUser) => {
  const segmentIdentity = getSegmentIdentityFromState(user);
  window.analytics.identify(user.id, segmentIdentity);
};

/**
 * We should only call segment `identify` for a user that has already been
 * identified during this session if the user details that we report in the
 * `identify` call have changed.
 */
export const maybeIdentifyUserAgainSegment = (
  user: EncryptedUser,
  prevUser: EncryptedUser,
) => {
  const previousSegmentIdentity = !isNullish(prevUser)
    ? getSegmentIdentityFromState(prevUser)
    : null;

  const newSegmentIdentity = getSegmentIdentityFromState(user);
  const hasSegmentIdentityUpdated = !isEqual(
    previousSegmentIdentity,
    newSegmentIdentity,
  );

  if (hasSegmentIdentityUpdated) {
    identifyAuthenticatedUserSegment(user);
  }
};

export const trackEventSegment = (event: TrackingEvent, properties: SegmentTrackProperties) =>
  window.analytics.track(event, properties);
