import {
  CreateOrderRequest,
  CreatePaymentOrSetupIntentRequest,
  CreatePaymentOrSetupIntentResponse,
  CreateUserWithEmailLinkRequest,
  CreateUserWithEmailLinkResponse,
  GetAlgoliaAPIKeyForSlugOnOfficesRequest,
  GetAlgoliaAPIKeyResponse,
  GetIncompleteUserAccessMetadataRequest,
  GetIncompleteUserAccessMetadataResponse,
  GetOrderDataForBookingRequest,
  GetOrderDataForBookingResponse,
  GetTokenForIncompleteUserAccessRequest,
  GetTokenForIncompleteUserAccessResponse,
  GetUserHmacKeyRequest,
  GetUserHmacKeyResponse,
  GetUserOrdersRequest,
  GetUserOrdersResponse,
  GetUserPasswordCreatedRequest,
  GetUserPasswordCreatedResponse,
  HandlePaymentIntentSucceededRequest,
  HandlePaymentIntentSucceededResponse,
  HandleSetupIntentSucceededRequest,
  HandleSetupIntentSucceededResponse,
  RefreshTokenOnFailureRequest,
  RefreshTokenOnFailureResponse,
  UpdateOrderRequest,
  UpdateOrderResponse,
} from '@caresend/types';
import { firebaseCallable, getDraftWaypointDataRequest } from '@caresend/ui-components';
import { getUnknownErrorMessage } from '@caresend/utils';

export const checkEmailAlreadyInUseRequest = firebaseCallable<
{ email: string },
{ isInUse: boolean; userID?: string }
>('checkEmailAlreadyInUse');

export const createOrderRequest = firebaseCallable<
CreateOrderRequest,
void
>('createOrder');

export const createPaymentOrSetupIntentRequest = firebaseCallable<
CreatePaymentOrSetupIntentRequest,
CreatePaymentOrSetupIntentResponse
>('createPaymentOrSetupIntent');

export const createUserWithEmailLinkRequest = firebaseCallable<
CreateUserWithEmailLinkRequest,
CreateUserWithEmailLinkResponse
>('createUserWithEmailLink');

export const getAlgoliaAPIKeyForSearchSlugOnOfficesRequest = firebaseCallable<
GetAlgoliaAPIKeyForSlugOnOfficesRequest,
GetAlgoliaAPIKeyResponse
>('getAlgoliaAPIKeyForSearchSlugOnOffices');

export const getIncompleteUserAccessMetadataRequest = firebaseCallable<
GetIncompleteUserAccessMetadataRequest,
GetIncompleteUserAccessMetadataResponse
>('getIncompleteUserAccessMetadata');

export const getOrderDataForBookingRequest = firebaseCallable<
GetOrderDataForBookingRequest,
GetOrderDataForBookingResponse
>('getOrderDataForBooking');

export const getTokenForIncompleteUserAccessRequest = firebaseCallable<
GetTokenForIncompleteUserAccessRequest,
GetTokenForIncompleteUserAccessResponse
>('getTokenForIncompleteUserAccess');

export const getUserHmacKeyRequest = firebaseCallable<
GetUserHmacKeyRequest,
GetUserHmacKeyResponse
>('getUserHmacKey');

export const getUserOrdersRequest = firebaseCallable<
GetUserOrdersRequest,
GetUserOrdersResponse
>('getUserOrders');

export const getUserPasswordCreatedRequest = firebaseCallable<
GetUserPasswordCreatedRequest,
GetUserPasswordCreatedResponse
>('getUserPasswordCreated');

export const handlePaymentIntentSucceededRequest = firebaseCallable<
HandlePaymentIntentSucceededRequest,
HandlePaymentIntentSucceededResponse
>('handlePaymentIntentSucceeded');

export const handleSetupIntentSucceededRequest = firebaseCallable<
HandleSetupIntentSucceededRequest,
HandleSetupIntentSucceededResponse
>('handleSetupIntentSucceeded');

export const refreshTokenOnFailureRequest = firebaseCallable<
RefreshTokenOnFailureRequest,
void | RefreshTokenOnFailureResponse
>('refreshTokenOnFailure');

export const updateOrderRequest = firebaseCallable<
UpdateOrderRequest,
UpdateOrderResponse
>('updateOrder');

export const validateTokenRequest = firebaseCallable<
{ token: string },
{ userID: string }
>('validateToken');

// TODO: Strongly type all cloud functions and refactor function calls
// NOTE: When registering a new cloud function, use firebaseCallable()
// and strongly define request and return types! Let's move away from directly
// using httpsCallable().

export const addMembersToUserRequest = firebaseCallable<
unknown,
unknown
>('addMembersToUser');

export const changeUserEmailAddressRequest = firebaseCallable<
unknown,
unknown
>('changeUserEmailAddress');

export const resetPasswordRequest = firebaseCallable<
unknown,
unknown
>('resetUserPassword');

// Do not add any more cloud functions here, put them in alphabetical order
// in the top section.
export const handleWarmUpError = (error: Error) => {
  const errorMessage = getUnknownErrorMessage(error);
  console.info(`Error warming up functions: ${errorMessage}`);
};

/** Warming up global used cloud functions */
export const warmUpCloudFunctions = () => {
  getDraftWaypointDataRequest({ warmUp: true })
    .catch(handleWarmUpError);
};
