
import Vue, { PropType } from 'vue';

type Colors = 'green' | 'blue';

export default Vue.extend({
  props: {
    title: {
      type: String as PropType<string | null>,
      default: null,
    },
    icon: {
      type: String,
      default: 'exclamation-circle',
    },
    color: {
      type: String as PropType<Colors>,
      default: 'green',
    },
  },
  data() {
    return {};
  },
  computed: {
    hasTitleAndContent(): boolean {
      return this.title !== null && this.$slots.default !== undefined;
    },
  },
});
