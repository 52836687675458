import { DbRef, Office } from '@caresend/types';
import {
  ExtendedCustomModule,
  OfficesModule,
  OfficesState,
  firebaseBind,
  firebaseUnbind,
  initOfficesModule,
} from '@caresend/ui-components';

import type { CustomActionContext } from '@/store/model';
import { ExtendedUsersMutations } from '@/store/modules/users';

interface ExtraOfficesState {
  partnerOffice?: Office;
  partnerInfo?: Office['info'];
  partnerID?: string;
}

type S = OfficesState & ExtraOfficesState;

const extraOfficesState: ExtraOfficesState = {
  partnerOffice: undefined,
  partnerInfo: undefined,
  partnerID: undefined,
};

const extraOfficesMutations = {
  'offices/RESET_PARTNER_INFO': (state: S) => {
    state.partnerInfo = undefined;
    state.partnerID = undefined;
  },

  'offices/SET_PARTNER_INFO': (
    state: S,
    { officeID, partnerInfo }: { officeID: string; partnerInfo: Office['info'] },
  ) => {
    state.partnerInfo = partnerInfo;
    state.partnerID = officeID;
  },

  'offices/SET_PARTNER_OFFICE': (
    state: S,
    partnerOffice: Office,
  ) => {
    state.partnerInfo = partnerOffice.info;
    state.partnerID = partnerOffice.id;
    state.partnerOffice = partnerOffice;
  },
};

type ExtraOfficesActionContext = CustomActionContext<'oldOrderFlow', S>;

export type ExtraOfficesActions = {
  'offices/bindPartner': (
    context: ExtraOfficesActionContext,
    officeID: string,
  ) => Promise<void>;

  'offices/unbindPartner': (
    context: ExtraOfficesActionContext,
  ) => void;
};

const extraOfficesActions: ExtraOfficesActions = {
  'offices/bindPartner': async ({ commit }, officeID) => {
    const path = `${DbRef.OFFICES}/${officeID}/info`;
    await new Promise<void>((resolve) => {
      firebaseBind<Office['info']>(path, (partnerInfo) => {
        commit('offices/SET_PARTNER_INFO', { officeID, partnerInfo });
        resolve();
      });
    });
  },

  'offices/unbindPartner': ({ commit, state }) => {
    const officeID = state.partnerID;
    if (officeID) {
      const path = `${DbRef.OFFICES}/${officeID}/info`;
      firebaseUnbind(path);
      commit('offices/RESET_PARTNER_INFO');
    }
  },
};

const officesModuleExtension = {
  state: extraOfficesState,
  mutations: extraOfficesMutations,
  actions: extraOfficesActions,
};

export const officesModule: ExtendedCustomModule<
OfficesModule<ExtendedUsersMutations>,
typeof officesModuleExtension
> = initOfficesModule(officesModuleExtension);

export type ExtendedOfficesModule = typeof officesModule;

export type ExtendedOfficesMutations = ExtendedOfficesModule['mutations'];
export type ExtendedOfficesActions = ExtendedOfficesModule['actions'];
export type ExtendedOfficesGetters = ExtendedOfficesModule['getters'];
