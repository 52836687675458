import { EncryptedUser, MedicalTitleName, StaffUser } from '@caresend/types';

export const formatFullName = (
  user: EncryptedUser | StaffUser | null,
  withMiddle = false,
  withTitle = true,
): string => {
  const { firstName, middleNames, lastName } = user?.info ?? {};
  const title = user?.professionalInfo?.title;

  if (firstName === undefined && lastName === undefined) return 'Unknown Name';
  const formattedMiddleNames = middleNames && withMiddle ? ` ${middleNames}` : '';

  let formattedTitle = '';
  if (withTitle && title) {
    const titleNeedsUppercase = Object.values(MedicalTitleName)
      .includes(title as MedicalTitleName);
    const casedTitle = titleNeedsUppercase ? title.toUpperCase() : title;
    formattedTitle = `, ${casedTitle}`;
  }

  return `${firstName ?? ''}${formattedMiddleNames} ${lastName ?? ''}${formattedTitle}`;
};

/**
 * Formats a user's full name with the last name initial.
 *
 * Example:
 * - Input: John Smith
 * - Output: John S
 */
export const formatFullNameWithInitial = (
  user: EncryptedUser | StaffUser | null,
  withMiddle = false,
  withTitle = true,
): string => {
  const fullName = formatFullName(user, withMiddle, withTitle);
  const nameParts = fullName.split(' ');

  if (nameParts.length < 2) return fullName;

  const lastName = nameParts[nameParts.length - 1];
  const lastNameInitial = lastName?.charAt(0) ?? '';

  nameParts[nameParts.length - 1] = lastNameInitial;

  return nameParts.join(' ');
};
