
import { ButtonComponent } from '@caresend/ui-components';
import { PropType, computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'BookingNextButton',
  components: { ButtonComponent },
  props: {
    label: {
      type: String as PropType<string | null>,
      default: null,
    },
    disabled: Boolean,
    loading: Boolean,
  },
  setup(props) {
    const text = computed(() => props.label ?? 'Next');

    return {
      text,
    };
  },
});
