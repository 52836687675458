import { ExtendedCustomModule, OrderFlowModule, initOrderFlowModule } from '@caresend/ui-components';

import type { RootState } from '@/store/model';
import type { ExtendedOfficesActions, ExtendedOfficesMutations } from '@/store/modules/offices';
import { ExtendedVariablesGetters } from '@/store/modules/variables';

const moduleExtension = {};

export const orderFlowModule: ExtendedCustomModule<
OrderFlowModule<
RootState,
ExtendedOfficesMutations,
ExtendedOfficesActions,
ExtendedVariablesGetters
>,
typeof moduleExtension
> = initOrderFlowModule({});

export type ExtendedOrderFlowModule = typeof orderFlowModule;

export type ExtendedOrderFlowMutations = ExtendedOrderFlowModule['mutations'];
export type ExtendedOrderFlowActions = ExtendedOrderFlowModule['actions'];
export type ExtendedOrderFlowGetters = ExtendedOrderFlowModule['getters'];
