import { ExtendedCustomModule, VariablesModule, initVariablesModule } from '@caresend/ui-components';

import type { RootState } from '@/store/model';

const variablesModuleExtension = {};

export const variablesModule: ExtendedCustomModule<
VariablesModule<RootState>,
  typeof variablesModuleExtension
> = initVariablesModule(variablesModuleExtension);

export type ExtendedVariablesModule = typeof variablesModule;

export type ExtendedVariablesMutations = ExtendedVariablesModule['mutations'];
export type ExtendedVariablesActions = ExtendedVariablesModule['actions'];
export type ExtendedVariablesGetters = ExtendedVariablesModule['getters'];
