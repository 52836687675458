
import { PropType, computed, defineComponent } from 'vue';

import { IconName } from '@/assets/icons/model';
import SVGIcon from '@/assets/icons/SVGIcon.vue';

export default defineComponent({
  name: 'PWBIcon',
  components: { SVGIcon },
  props: {
    icon: {
      type: String as PropType<string>,
      required: true,
    },
    svgIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const containerClassNames = ['PWBIcon__round-icon', 'main-center'];
    if (props.svgIcon) containerClassNames.push('PWBIcon__round-icon-grey');

    const svgIconName = computed<IconName>(() => {
      if (
        props.svgIcon
        && Object.values<string>(IconName).includes(props.icon)
      ) return props.icon as IconName;
      return IconName.EMAIL;
    });

    return {
      containerClassNames,
      svgIconName,
    };
  },
});
