import { ExtendedCustomModule, ProceduresModule, initProceduresModule } from '@caresend/ui-components';

import type { RootState } from '@/store/model';
import { ExtendedOrdersGetters } from '@/store/modules/orders';
import { ExtendedUsersGetters } from '@/store/modules/users';

const proceduresModuleExtension = {
};

export const proceduresModule: ExtendedCustomModule<
ProceduresModule<RootState, ExtendedUsersGetters & ExtendedOrdersGetters>,
  typeof proceduresModuleExtension
> = initProceduresModule(proceduresModuleExtension);

export type ExtendedProceduresModule = typeof proceduresModule;

export type ExtendedProceduresMutations = ExtendedProceduresModule['mutations'];
export type ExtendedProceduresActions = ExtendedProceduresModule['actions'];
export type ExtendedProceduresGetters = ExtendedProceduresModule['getters'];
