import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';
import { PasswordMode } from '@/views/signUp/CreatePassword/model';

const routesSignUp: RouteConfig[] = [
  {
    path: '/signup/verifyemail',
    name: routeNames.VERIFY_EMAIL,
    component: () => import(
      /* webpackChunkName: 'chunk-signup' */
      '@/views/signUp/VerifyEmail.vue'
    ),
    meta: {
      hideSignInButton: true,
    },
    props: true,
  },
  {
    path: '/:partnerName?/finishSignUp',
    name: routeNames.FINISH_SIGN_UP,
    component: () => import(
      /* webpackChunkName: 'chunk-signup' */
      '@/views/signUp/CreatePassword/CreatePassword.vue'
    ),
    meta: {
      hideSignInButton: true,
    },
    props: {
      passwordMode: PasswordMode.CREATE,
    },
  },
  {
    path: '/resetPassword',
    name: routeNames.RESET_PASSWORD,
    component: () => import(
      /* webpackChunkName: 'chunk-reset-password' */
      '@/views/signUp/CreatePassword/CreatePassword.vue'
    ),
    meta: {
      hideSignInButton: true,
    },
    props: {
      passwordMode: PasswordMode.RESET,
    },
  },
];

export { routesSignUp };
