
// TODO: bring into compliance with vue/require-default-prop
/* eslint-disable vue/require-default-prop */

import Vue from 'vue';

export default Vue.extend({
  props: {
    src: String,
    text: String,
  },
});
