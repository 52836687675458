
/*
 * If you add an SVG icon to this assets folder, make sure
 * to declare a shorthand name in the IconName enum and
 * import the asset in the iconComponents object. - Pumposh
 */
import { WithStringUnion } from '@caresend/types';
import { PropType, defineComponent } from 'vue';

import { IconName } from '@/assets/icons/model';

const iconComponents: Record<IconName, () => Promise<typeof import('*.svg')>> = {
  [IconName.EMAIL]: () => import(
    /* webpackChunkName: 'chunk-email-svg' */
    '@/assets/icons/Email.svg'
  ),
  [IconName.REQ_YES]: () => import(
    /* webpackChunkName: 'chunk-req-yes-svg' */
    '@/assets/icons/ReqFormIcons/ReqYES.svg'
  ),
  [IconName.REQ_NO]: () => import(
    /* webpackChunkName: 'chunk-req-no-svg' */
    '@/assets/icons/ReqFormIcons/ReqNO.svg'
  ),
  [IconName.REQ_FORM]: () => import(
    /* webpackChunkName: 'chunk-req-form-upload-svg' */
    '@/assets/icons/ReqFormIcons/ReqFormUpload.svg'
  ),
};

export default defineComponent({
  name: 'SVGIcon',
  props: {
    icon: {
      type: String as PropType<WithStringUnion<IconName>>,
      required: true,
    },
  },
  setup(props) {
    const iconComponent = iconComponents[props.icon];
    if (!iconComponent) throw new Error(`SvgIcon.vue -- No SVG icon is defined of the name "${props.icon}"`);
    return { iconComponent };
  },
});
