import { isProd } from '@caresend/ui-components';

export const PLACES_TOKEN = 'AIzaSyCLcCBd6iK37JUMngt6Z7ZM6zUdh7Gq9oY';

export const ALGOLIA_APP_ID = '734Q94UIDR';

export const STRIPE_PUBLISHABLE_KEY = isProd
  ? 'pk_live_51IHzrfEoBSNVKZohcGCi8eeWmXrKU9w911iP5Ns4B7JDTih4qYBIlTa9OMm6jfWuzkDzkKhzNk0rTXvG7OI8jV7700MHA8X254'
  : 'pk_test_51IHzrfEoBSNVKZohqdYZ9tisHlc68rSCeboDgqhHlsw2z7hN7IslNRFrYjdRNZGzT7LyxjCrtzlNnNlTNXzvLwaS00y2zLIyEy';

export const SEGMENT_TOKEN = isProd
  ? 'vcGOHvoPp4Q7f6tCxXEe3OU7EXCTV8uX'
  : 'Jf8jVqexcw4avF1ka1LgiEtsNt4fmeJb';

export const SENTRY_DSN = 'https://5ee8f416bf324ae999b347f3c0ba8f54@o878771.ingest.sentry.io/5835822';

export const FROM_CARESEND_EMAIL = 'team@caresend.co';
