/* eslint-disable no-console */
import { toastError } from '@caresend/ui-components';
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.info('App is being served from cache by a service worker.');
    },
    registered() {
      console.info('Service worker has been registered.');
    },
    cached() {
      console.info('Content has been cached for offline use.');
    },
    updatefound() {
      console.info('New content is downloading.');
    },
    updated(registration) {
      console.info('Service worker has downloaded an update');

      // Attempt to prevent refresh loop on first visit for users with an old
      // service worker. (CARE-2031)
      const storedVersionNumber = localStorage.getItem('versionNumber');
      if (storedVersionNumber) {
        localStorage.removeItem('versionNumber');
        registration.waiting?.postMessage({ action: 'skipWaiting' });
      }
    },
    offline() {
      toastError('No internet connection found.');
    },
    error(error) {
      // eslint-disable-next-line no-console
      console.error('Error during service worker registration:', error);
    },
  });
}
