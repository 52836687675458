// This rule will not be active when eslintrc of dashboard is upgraded.
/* eslint-disable import/prefer-default-export */

import dayjs from 'dayjs';

export type DateFormat = 'MM/DD/YYYY' |
'MM/DD/YY' |
'M/D/YYYY' |
'MMMM D' |
'dddd, MMMM D' |
'dddd, MMMM D, YYYY' |
'M/D/YYYY, h:mm a' |
'h:mm a' |
'h:mm A';
export type DateFormatFunction = (timestamp?: number, format?: DateFormat) => string;

const dayjsToTime = (time: dayjs.Dayjs) => {
  const isAbridged = time.minute() === 0;

  const abridgedFormat = 'h a';
  const fullFormat = 'h:mm a';

  return isAbridged
    ? time.format(abridgedFormat)
    : time.format(fullFormat);
};

export const numberToTime = (timeNumber: number): string => {
  const time = dayjs(timeNumber);
  return dayjsToTime(time);
};

export const militaryHourToTime = (timeNumber: number): string => {
  const stringNumber = String(timeNumber).padStart(4, '0');
  const reformattedTime = dayjs(stringNumber, 'hmm');
  return dayjsToTime(reformattedTime);
};

export const yesterday = new Date(Date.now() - 86400000);

export const isBeforeToday = (date: number) => date < (dayjs(Date.now()).startOf('d').valueOf() / 1000);

export const formatFixedDate: DateFormatFunction = (timestamp, format = 'MM/DD/YYYY') => {
  if (!timestamp) return 'Invalid date';
  return dayjs.utc(timestamp).format(format);
};

export const formatDateOfBirth: DateFormatFunction = (timestamp, format = 'MM/DD/YYYY') =>
  formatFixedDate(timestamp, format);
