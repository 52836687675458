import { getUserHmacKeyRequest } from '@/database/firebase/API';

export const getUserHmacKey = async (userID: string) => {
  let key = localStorage.getItem('cachedHmacKey');
  if (!key) {
    const hmacKeyRequest = await getUserHmacKeyRequest({ userID });
    await localStorage.setItem('cachedHmacKey', hmacKeyRequest.key);
    key = hmacKeyRequest.key;
  }
  return key;
};

export const setIntercomUserInfo = async ({ firstName, lastName, email, userID }: {
  firstName: string;
  lastName: string;
  email: string;
  userID: string;
}) => {
  const name = `${firstName} ${lastName}`;
  const userHmacKey = await getUserHmacKey(userID);
  (window as any).Intercom('update', { name, user_id: userID, email, user_hash: userHmacKey });
  (window as any).Upscope('updateConnection', {
    uniqueId: userID,
    identities: [name, email],
  });
};

export const showIntercomButton = () => {
  (window as any).Intercom('update', {
    hide_default_launcher: false,
  });
};

export const hideIntercomButton = () => {
  (window as any).Intercom('update', {
    hide_default_launcher: true,
  });
};

export const showIntercomMessenger = () => {
  showIntercomButton();
  (window as any).Intercom('show');
  (window as any).Intercom('onHide', () => {
    hideIntercomButton();
  });
};
