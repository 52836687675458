import { toastError } from '@caresend/ui-components';

import { changeUserEmailAddressRequest } from '@/database/firebase/API';

export const changeUserEmailAddress = (email: string, newEmail: string): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    changeUserEmailAddressRequest({ email, newEmail })
      .then(() => resolve())
      .catch((error) => reject(toastError(error.message)));
  });
