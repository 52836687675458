import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesHome: Array<RouteConfig> = [
  {
    path: '/:partnerName?/',
    name: routeNames.HOME,
    component: () => import(
      /* webpackChunkName: 'chunk-main' */
      /* webpackMode: 'eager' */
      '@/views/Home.vue'
    ),
    meta: {
      allowIncomplete: true,
      authRequired: true,
    },
    props: true,
  },
];

export { routesHome };
