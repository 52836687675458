
// TODO: bring into compliance with vue/require-default-prop
/* eslint-disable vue/require-default-prop */

import { InfoUser } from '@caresend/types';
import { DatePicker, SelectComponent, TextInput } from '@caresend/ui-components';
import Vue, { PropType } from 'vue';

import Notes from '@/components/layout/rows/Notes.vue';

export default Vue.extend({
  name: 'PersoInfo',
  components: { DatePicker, Notes, SelectComponent, TextInput },
  props: {
    infoProps: Object as PropType<InfoUser>,
    minimal: Boolean,
    hideNote: Boolean,
  },
  data() {
    return {
      info: this.infoProps,
      genders: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
      ],
    };
  },
  computed: {
    isValid(): boolean {
      return (
        !!this.info.firstName
        && !!this.info.lastName
        && (this.minimal || (!!this.info.dateOfBirth && !!this.info.gender))
      );
    },
  },
  watch: {
    isValid: {
      handler(isValid) {
        this.$emit('input', isValid);
      },
      immediate: true,
    },
    infoProps: {
      handler(infoProps) {
        this.info = infoProps;
      },
      deep: true,
    },
    info: {
      handler(info) {
        this.$emit('setInfo', info);
      },
      deep: true,
    },
  },
});
