
import { defineComponent } from 'vue';

import PageWithIcon from '@/components/layout/pages/PageWithIcon/PageWithIcon.vue';

export default defineComponent({
  name: 'PageNotFound',
  components: {
    PageWithIcon,
  },
});
