import VueRouter, { NavigationGuardNext, Route } from 'vue-router';

import { getMatchedMeta, routeNames } from '@/router/model';

export default (router: VueRouter) => ({
  allowFeature: (
    to: Route,
    _from: Route,
    next: NavigationGuardNext<Vue>,
  ) => {
    const store = router.app.$store;
    const { featureFlag } = getMatchedMeta(to);
    if (featureFlag) {
      const flag = store.getters['variables/getFeatureFlagByName'](featureFlag);
      if (!flag && flag !== null) {
        return next({ name: routeNames.HOME });
      }
    }
    return next();
  },
});
