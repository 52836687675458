import { ExtendedCustomModule, UsersModule, initUsersModule } from '@caresend/ui-components';

export const usersModuleExtension = {
};

export const usersModule: ExtendedCustomModule<
UsersModule,
typeof usersModuleExtension
> = initUsersModule(usersModuleExtension);

export type ExtendedUsersModule = typeof usersModule;

export type ExtendedUsersMutations = ExtendedUsersModule['mutations'];
export type ExtendedUsersActions = ExtendedUsersModule['actions'];
export type ExtendedUsersGetters = ExtendedUsersModule['getters'];
