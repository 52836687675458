
import { PropType, computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String as PropType<string | null>,
      default: null,
    },
    subtitle: {
      type: String as PropType<string | null>,
      default: null,
    },
    divider: Boolean,
    center: Boolean,
    size: {
      type: String as PropType<'md' | 'sm'>,
      default: 'md',
    },
    noSpacing: Boolean,
  },
  setup(props) {
    const classNames = computed(() => {
      const names = [];

      switch (props.size) {
        case 'sm':
          names.push('FormHeader--sm');
          break;
        case 'md':
          names.push('FormHeader--md');
      }

      if (props.center) {
        names.push('text-center');
      }

      return names;
    });

    return {
      classNames,
    };
  },
});
