import { AuthRoute } from '@caresend/types';
import { firebaseAuth, toastError } from '@caresend/ui-components';
import { User as FirebaseUser, signInWithCustomToken } from 'firebase/auth';
import { Route } from 'vue-router';

import { patientURL } from '@/data/urls';
import { refreshTokenOnFailureRequest } from '@/database/firebase/API';

export const signInWithToken = async (
  { token, retry, to }: { token: string; retry?: boolean; to?: Route },
): Promise<FirebaseUser | null> => {
  try {
    const user = await signInWithCustomToken(firebaseAuth, token);
    return user.user;
  } catch {
    if (retry) throw toastError('Something went wrong. Contact support to access your account.');
    const route = to?.path.replace('/', '') as AuthRoute;
    try {
      const response = await refreshTokenOnFailureRequest({
        emailLinkURL: patientURL,
        route,
        token,
      });
      if (!response?.token) {
        toastError('This token has expired. Check your email for a new link.');
        return null;
      }

      const { token: newToken } = response;
      const authUser = await signInWithToken({ token: newToken, retry: true, to });
      return authUser;
    } catch (error) {
      toastError('Something went wrong. Contact support to access your account.');
      return null;
    }
  }
};
