import { EncryptedUser, PhoneNumber, Role, UserStatus } from '@caresend/types';

export const initialPhone = (): PhoneNumber => ({
  callingCode: '+1',
  countryCode: 'US',
  number: '',
});

export const initPatient = (email?: string): EncryptedUser => ({
  creationTimestamp: Date.now(),
  id: '',
  info: {
    address: {
      formattedAddress: '',
      lat: 0,
      lng: 0,
      addressComponents: [],
    },
    dateOfBirth: undefined,
    firstName: '',
    lastName: '',
    email: email ?? '',
    middleNames: '',
    phone: {
      callingCode: '',
      countryCode: '',
      number: '',
    },
  },
  passwordCreated: false,
  role: Role.PATIENT,
  status: UserStatus.INCOMPLETE,
});

export const emptyUsers: Array<EncryptedUser> = [];
