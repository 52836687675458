import { ByID, Transaction } from '@caresend/types';
import update from 'immutability-helper';

import { TransactionsState } from '@/store/modules/transactions/model';

type S = TransactionsState;

const transactionsState: S = {
  transactions: {},
};

export const bookingsMutations = {
  'transactions/RESET_TRANSACTIONS': (state: S) => {
    state.transactions = {};
  },

  'transactions/SET_TRANSACTIONS': (state: S, transactions: ByID<Transaction>) => {
    state.transactions = {
      ...state.transactions,
      ...transactions,
    };
  },

  'transactions/UNSET_TRANSACTION': (state: S, transactionID: string) => {
    if (state.transactions) {
      state.transactions = update(state.transactions, {
        $unset: [transactionID],
      });
    }
  },
};

export const transactionsGetters = {
  'transactions/getTransactionByID': (state: S) => (id: string | undefined): Transaction | null =>
    id ? state.transactions?.[id] ?? null : null,
};

export type TransactionsGetters = typeof transactionsGetters;

export const transactionsModule = {
  state: transactionsState,
  mutations: bookingsMutations,
  actions: {},
  getters: transactionsGetters,
};

export type TransactionsModule = typeof transactionsModule;
