import { getStore } from '@caresend/ui-components';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';

import { getOfficeBySlugName } from '@/functions/office';
import { routeNames } from '@/router/model';

/**
  * Redirects to home if a partner route is invalid.
  *
  * i.e., `/nike` → `PartnerHome`, `/nikee` → `Home`.
  */
export default (router: VueRouter) => ({
  checkPartnerRoute: async (
    to: Route,
    _from: Route,
    next: NavigationGuardNext<Vue>,
  ) => {
    const { partnerName } = to.params;
    const store = getStore();

    if (partnerName) {
      const removePartner = () => {
        router.app.$store.dispatch('offices/unbindPartner');
        return next({
          name: routeNames.HOME,
          params: {
            ...router.currentRoute.params, partnerName: null,
          },
        });
      };

      if (router.app.$store.state.offices.partnerInfo?.slugName === partnerName) {
        return next();
      }

      const office = await getOfficeBySlugName(partnerName);

      if (!office) return removePartner();

      store.commit('offices/SET_PARTNER_OFFICE', office);
      store.dispatch('offices/bindPartner', office.id);

      return next();
    }

    if (store.state.offices.partnerInfo) {
      store.dispatch('offices/unbindPartner');
    }
    return next();
  },
});
