import { buildMaybePartnerRoute } from '@caresend/ui-components';

import { LocationData, routeNames } from '@/router/model';

export const finishSignUpRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.FINISH_SIGN_UP });

export const forgotPasswordRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.FORGOT_PASSWORD });

export const loginRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.LOGIN });

export const pageNotFoundRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.PAGE_NOT_FOUND });

export const resetPasswordRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.RESET_PASSWORD });

export const userSettingsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.USER_SETTINGS });

export const verifyEmailRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.VERIFY_EMAIL });
