export const sanitizeData = (data: any) => JSON.parse(JSON.stringify(data));

export const emailIsValid = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const formatPrice = (cents: number): string => {
  if (cents % 100 === 0) {
    // Ex: $99
    return `$${cents / 100}`;
  }

  // Ex: $99.99
  return `$${(cents / 100).toFixed(2)}`;
};
