
import { CenteredPage, Circles, getStore, useNavBarHeight } from '@caresend/ui-components';
import { computed, defineComponent, onMounted, ref } from 'vue';

import { formatFullNameWithInitial } from '@/functions/user';
import { userSettingsRoute } from '@/router/locations';
import VisitsList from '@/views/visits/VisitList.vue';

export default defineComponent({
  name: 'Home',
  components: {
    CenteredPage,
    Circles,
    VisitsList,
  },
  setup() {
    const store = getStore();

    const headerBg = ref<HTMLElement | null>(null);

    onMounted(() => {
      const { syncContentMarginWithNavBarHeight } = useNavBarHeight();
      syncContentMarginWithNavBarHeight(headerBg);
    });

    const formattedName = computed<string>(() =>
      formatFullNameWithInitial(store.state.auth.user ?? null),
    );

    return {
      headerBg,
      formattedName,
      userSettingsRoute,
    };
  },
});
