
// TODO: bring into compliance with vue/require-default-prop
/* eslint-disable vue/require-default-prop */

import { PhoneNumberInput, PhoneNumberInputEvent } from '@caresend/ui-components';
import Vue from 'vue';

import EmailInput from '@/components/inputs/EmailInput.vue';

export default Vue.extend({
  name: 'Credentials',
  components: { EmailInput, PhoneNumberInput },
  props: {
    infoProps: Object,
    isEmailOptional: { type: Boolean, required: false },
    invalidEmails: { type: Array, default: () => [] },
    disableEmailField: { type: Boolean, required: false },
    userCanExist: { type: Boolean, required: false },
    isUserCreated: { type: Boolean, required: false },
  },
  data() {
    return {
      phoneNumberInput: this.infoProps.phone?.number ?? '',
      phoneNumberValid: false,
      emailValid: false,
      info: this.infoProps,
    };
  },
  computed: {
    isValid(): boolean {
      if (this.isEmailOptional) return this.phoneNumberValid;
      return this.emailValid && this.phoneNumberValid;
    },
  },
  watch: {
    isValid: {
      handler(isValid) {
        this.$emit('input', isValid);
      },
      immediate: true,
    },
    infoProps: {
      handler(infoProps) {
        this.info = infoProps;
      },
      deep: true,
    },
    info: {
      handler(info) {
        this.$emit('setInfo', info);
      },
      deep: true,
    },
  },
  methods: {
    updatedPhoneNumber(data: PhoneNumberInputEvent) {
      this.info.phone = data.phone;
      this.phoneNumberValid = data.isValid;
    },
    setIsEmailValid(valid: boolean) {
      this.emailValid = valid;
    },
  },
});
