import { DbRef, EncryptedUser, InfoUser, Role } from '@caresend/types';
import {
  AuthModule,
  AuthState,
  ExtendedCustomModule,
  dbGroupSet,
  firebaseAuth,
  getRouter,
  homeRoute,
  initAuthModule,
  toastError,
  toastErrorAndReport,
  toastSuccess,
} from '@caresend/ui-components';

import { setIntercomUserInfo } from '@/database/intercom/methods';
import { changeUserEmailAddress } from '@/functions/auth';
import { identifyAuthenticatedUserSegment, maybeIdentifyUserAgainSegment } from '@/functions/tracking';
import { loginRoute } from '@/router/locations';
import type { CustomActionContext, CustomStore } from '@/store/model';

type S = AuthState;

type ExtraAuthActionContext = CustomActionContext<'auth', S>;

export type ExtraAuthActions = {
  'auth/updateUserInfo': (
    context: ExtraAuthActionContext,
    payload: {
      userInfo: InfoUser;
      oldEmail: string | undefined;
    }
  ) => Promise<void>;
};

const extraAuthActions: ExtraAuthActions = {
  'auth/updateUserInfo': async ({ state }, { userInfo, oldEmail }) => {
    const updates: Record<string, InfoUser> = {};
    const userID = state.user?.id;
    if (!userID) throw new Error('Missing office ID when saving billing settings.');

    const path = `${DbRef.USERS}/${userID}/info`;
    updates[path] = userInfo;

    try {
      if (oldEmail) {
        await changeUserEmailAddress(oldEmail, userInfo.email);
      }
      await dbGroupSet<InfoUser>(updates);
      toastSuccess('Information updated.');

      if (oldEmail) {
        firebaseAuth.signOut().then(() => {
          const nextRoute = loginRoute({ query: { redirect: homeRoute().path } });
          getRouter().push(nextRoute);
        });
      }
    } catch (error) {
      toastErrorAndReport(error);
    }
  },
};

const authModuleExtension = {
  actions: extraAuthActions,
};

export const authModule: ExtendedCustomModule<
AuthModule,
typeof authModuleExtension
> = initAuthModule(authModuleExtension);

export type ExtendedAuthModule = typeof authModule;

export type ExtendedAuthMutations = ExtendedAuthModule['mutations'];
export type ExtendedAuthActions = ExtendedAuthModule['actions'];
export type ExtendedAuthGetters = ExtendedAuthModule['getters'];

export const handleUserBound = async (_store: CustomStore, user: EncryptedUser) => {
  const userID = user?.id;

  const { role } = user;
  if (role !== Role.PATIENT) {
    toastError('User is not a patient.');
    firebaseAuth.signOut();
    return;
  }

  const { firstName, lastName, email } = user.info;
  setIntercomUserInfo({ firstName: firstName ?? '', lastName: lastName ?? '', email, userID });
  identifyAuthenticatedUserSegment(user);
};

export const handleUserObjectUpdated = async (
  _store: CustomStore,
  user: EncryptedUser,
  prevUser: EncryptedUser,
) => {
  maybeIdentifyUserAgainSegment(user, prevUser);
};

export const handleUserUnbound = async ({ dispatch }: CustomStore) => {
  dispatch('app/resetStore');
};
