
// TODO: bring into compliance with vue/require-default-prop
/* eslint-disable vue/require-default-prop */

import { EncryptedUser } from '@caresend/types';
import { ButtonComponent, getRouter } from '@caresend/ui-components';
import { PropType, computed, defineComponent, ref } from 'vue';

import FormHeader from '@/components/layout/text/FormHeader.vue';
import Credentials from '@/components/signUp/components/form/Credentials.vue';
import PersoInfo from '@/components/signUp/components/form/PersoInfo.vue';
import { initPatient } from '@/data/users/init';
import { registerUserWithoutPassword } from '@/database/firebase/auth';
import { loginRoute } from '@/router/locations';
import BookingBackButton from '@/views/login/subViews/BookingBackButton.vue';
import BookingNextButton from '@/views/login/subViews/BookingNextButton.vue';

export default defineComponent({
  name: 'BookingStepAuth',
  components: {
    BookingBackButton,
    BookingNextButton,
    ButtonComponent,
    Credentials,
    FormHeader,
    PersoInfo,
  },
  props: {
    stepTitle: {
      type: String,
      required: true,
    },
    hideLoginSection: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String as PropType<string | undefined>,
    },
  },
  setup(props) {
    const router = getRouter();

    const personalInfoValid = ref(false);
    const credentialsValid = ref(false);
    const isLoading = ref(false);
    const isValid = computed(() => !!personalInfoValid.value && !!credentialsValid.value);
    const user = ref<EncryptedUser>(initPatient(props.email));

    const createAccount = async () => {
      isLoading.value = true;
      await registerUserWithoutPassword(user.value, undefined, true);
    };

    const goToLogin = () => {
      const redirect = router.currentRoute.path;
      const nextRoute = loginRoute({ query: { redirect } });
      return router.push(nextRoute);
    };

    return {
      createAccount,
      credentialsValid,
      goToLogin,
      isLoading,
      isValid,
      personalInfoValid,
      user,
    };
  },
});
